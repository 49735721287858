// @flow strict

import React from 'react';
import { Switch, Redirect, Route, withRouter } from 'react-router-dom';

// Components
import AppLoader from './components/AppLoader';

// Constants
import { ROUTES } from './utils/constants';

import Login from './views/Login';
import ClientPlantUserManagement from './views/ClientPlantUserManagement';
import NotFound from './views/NotFound';
import Homepage from './views/HomePage';
import CircuitElevation from './views/CircuitElevation';
import CircuitSetup from './views/CircuitSetup';
import CircuitTrends from './views/CircuitTrends';
import CircuitArchive from './views/CircuitArchive';
import MinchemDashboardPage from './views/MinchemDashboardPage';
import PlantTrends from './views/PlantTrends';
import PlantArchive from './views/PlantArchive';
import CircuitComputation from './views/CircuitComputation';
import IsothermManagement from './views/IsothermManagement';
import ReagentManagement from './views/ReagentManagement';
import DisclaimerManagement from './views/DisclaimerManagement';
import UserSettings from './views/UserSettings';
import ReportGenerationSystem from './views/ReportGenerationSystem';
import SolvExtractDashboardPage from './views/SolvExtractDashboardPage';
import SystemStatus from './microfrontends/SystemStatus';
import PlantDashboard from './microfrontends/PlantDashboard';
import Cyanex from './microfrontends/Cyanex';
import MinChemV2 from './microfrontends/MinChemV2/MinChemV2';

// Types
import type { ImmutableUser } from './services/Authentication/types';
import type { LocationType } from 'types';

// Utils
import { isSysAdmin, isSolvayUser, canAccessCyanex } from './utils/authentication';

type Props = {
    location: LocationType,
    user: ?ImmutableUser,
    userIsFetching: boolean,
    userIsReady?: boolean,
};

export class Routes extends React.PureComponent<Props> {
    static defaultProps = {
        userIsReady: false,
    };

    userIsReady = () => this.props.user && this.props.userIsReady;

    userIsFromBackend = () => this.props.user && this.props.user.get('backendUser');

    userHasRole = () => this.props.user && this.props.user.get('roles').size > 0;

    userIsOnLoginPage = () => this.props.location.pathname.match(ROUTES.LOGIN.match);

    render() {
        // If user is fetching/authenticating, display loader to avoid pop in rendered UI
        // Possible fix is to persist redux to avoid loosing user object on reload
        if (this.props.userIsFetching) {
            return <AppLoader messageId="containers.App.loadingUser" loading />;
        }

        // User would be redirected to the login page if;
        // No user, user is not from backend, has no role(s) or prop.userIsReady is false
        const loginRedirect =
            !this.userIsOnLoginPage() &&
            (!this.props.user ||
                !this.userIsFromBackend() ||
                !this.userHasRole() ||
                !this.userIsReady());

        const homeRedirect =
            this.userIsReady() &&
            this.userIsFromBackend() &&
            this.userHasRole() &&
            this.userIsOnLoginPage();

        const isAdmin = isSysAdmin(this.props.user);
        const isSAM = isSolvayUser(this.props.user);

        return (
            <React.Fragment>
                {loginRedirect ? <Redirect to={ROUTES.LOGIN.path} /> : null}
                {homeRedirect ? <Redirect to={ROUTES.HOME.path} /> : null}
                <Switch>
                    <Route path={ROUTES.LOGIN.path} component={Login} exact />
                    {this.props.user && (
                        <Route path={ROUTES.HOME.path} component={Homepage} exact />
                    )}
                    {this.props.user && (
                        <Route path={ROUTES.MINCHEM_DASHBOARD.path} exact>
                            <MinchemDashboardPage />
                        </Route>
                    )}
                    {this.props.user && (
                        <Route path={ROUTES.SOLVEXTRACT_DASHBOARD.path} exact>
                            <SolvExtractDashboardPage />
                        </Route>
                    )}
                    {isAdmin && (
                        <Route
                            path={ROUTES.CIRCUIT_ELEVATION.path}
                            component={CircuitElevation}
                            exact
                        />
                    )}
                    {this.props.user && (
                        <Route path={ROUTES.CIRCUIT_SETUP.path} component={CircuitSetup} exact />
                    )}
                    {this.props.user && (
                        <Route path={ROUTES.SETTINGS.path} component={UserSettings} exact />
                    )}
                    {this.props.user && (
                        <Route path={ROUTES.SYSTEM_STATUS.path} component={SystemStatus} exact />
                    )}
                    {canAccessCyanex(this.props.user) && (
                        <Route path={ROUTES.CYANEX.path} component={Cyanex} />
                    )}
                    {/* Note that the access to v2 is allowed here, but is checked in the MFE itself. */}
                    <Route path={ROUTES.MINCHEM_V2.path} component={MinChemV2} />
                    {// TODO: Add redirect logic if circuit is not a SolvExtract one
                    this.props.user && (
                        <Route path={ROUTES.CIRCUIT_TRENDS.path} component={CircuitTrends} exact />
                    )}
                    {this.props.user && (
                        <Route
                            path={ROUTES.CIRCUIT_ARCHIVE.path}
                            component={CircuitArchive}
                            exact
                        />
                    )}
                    {// TODO: Add redirect logic if circuit is not a SolvExtract one
                    this.props.user && (
                        <Route path={ROUTES.PLANT_TRENDS.path} component={PlantTrends} exact />
                    )}
                    {this.props.user && (
                        <Route path={ROUTES.PLANT_ARCHIVE.path} component={PlantArchive} exact />
                    )}
                    {this.props.user && (
                        <Route path={ROUTES.PLANT_REPORT.path} component={ReportGenerationSystem} />
                    )}
                    {this.props.user && (
                        <Route
                            path={ROUTES.PLANT_REPORTS.path}
                            component={ReportGenerationSystem}
                            exact
                        />
                    )}
                    {this.props.user && (
                        <Route path={ROUTES.PLANT_DASHBOARD.path} component={PlantDashboard} />
                    )}
                    {this.props.user && (
                        <Route
                            path={ROUTES.DATASET_COMPUTATION.path}
                            component={CircuitComputation}
                            exact
                        />
                    )}
                    {this.props.user && (
                        <Route path={ROUTES.ISOTHERMS.path} component={IsothermManagement} exact />
                    )}
                    {isAdmin && (
                        <Route path={ROUTES.REAGENTS.path} component={ReagentManagement} exact />
                    )}
                    {isAdmin && (
                        <Route path={ROUTES.MDR.path} component={ReagentManagement} exact />
                    )}
                    {isAdmin && (
                        <Route
                            path={ROUTES.CYANEX_REAGENTS.path}
                            component={ReagentManagement}
                        />
                    )}
                    {isAdmin && (
                        <Route
                            path={ROUTES.DISCLAIMERS.path}
                            component={DisclaimerManagement}
                            exact
                        />
                    )}
                    {isAdmin && (
                        <Route
                            path={ROUTES.CLIENTS.path}
                            component={ClientPlantUserManagement}
                            exact
                        />
                    )}
                    {isAdmin && (
                        <Route path={ROUTES.PLANTS.path} component={ClientPlantUserManagement} />
                    )}
                    {(isAdmin || isSAM) && (
                        <Route
                            path={ROUTES.USERS_PM.path}
                            component={ClientPlantUserManagement}
                            exact
                        />
                    )}
                    {isAdmin && (
                        <Route
                            path={ROUTES.USERS_SAM.path}
                            component={ClientPlantUserManagement}
                            exact
                        />
                    )}
                    {isAdmin && (
                        <Route
                            path={ROUTES.USERS_ADMIN.path}
                            component={ClientPlantUserManagement}
                            exact
                        />
                    )}
                    <Route component={NotFound} />
                </Switch>
            </React.Fragment>
        );
    }
}

export default withRouter(Routes);
